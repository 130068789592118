<template>
  <div>
    <action-header
      :is-loading="!consumption"
      :actions-model="headerModel"
      :page="page"
    />

    <div
      v-if="consumption && consumption.deliveryServiceStatus"
      class="q-pa-xs text-center bg-red-5"
    >
      <strong>{{ consumption.deliveryServiceStatus }}</strong>
    </div>

    <div class="q-px-xs q-py-md">
      <form-builder :schema="schema" />

      <div
        v-if="isRequestedTask"
        class="bg-warning q-pa-md rounded q-ma-sm"
      >
        {{ $t('Found items:') }} {{ totalTasksNumber }}
      </div>

      <q-card v-if="consumption && consumption.id" class="q-ma-sm">
        <q-card-section class="q-py-xs q-px-none">
          <q-legend
            :label="$t('Shipments')"
          />
        </q-card-section>

        <q-table
          style="height: calc(100vh - 250px);"
          row-key="id"
          :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="isLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template
            v-slot:body="props"
            class="clickable"
          >
            <delivery-request-row-table
              :data="props"
              :row="props.row"
              :column="props.column"
              @dbclick="onRowClick"
            />
          </template>
        </q-table>
      </q-card>
    </div>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations, mapActions } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader'
import DeliveryRequestRowTable from '../../components/delivery-request-row-table/DeliveryRequestRowTable.vue'

// Helpers
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'Consumption',
  components: {
    ActionHeader,
    DeliveryRequestRowTable
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Date'),
          name: 'created',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Customer'),
          name: 'customer',
          align: 'left'
        },
        {
          label: this.$t('Sender'),
          name: 'sender',
          align: 'left'
        },
        {
          label: this.$t('Shipping'),
          name: 'shipping',
          align: 'left'
        }
      ],
      isRequestedTask: false,
      isLoading: false,
      items: []
    }
  },
  computed: {
    ...mapGetters([
      'deliveryRequests',
      'deliveryRequestsLoading',
      'consumption',
      'tasks',
      'totalTasksNumber'
    ]),
    page () {
      return {
        id: this.consumption && this.consumption.id,
        name: this.consumption && this.consumption.id
          ? this.$t('Shipping manifest')
          : this.$t('New shipping manifest')
      }
    },
    headerModel () {
      if (!this.consumption) {
        return []
      }

      const newState = { id: 'new', title: this.$t('New'), transitions: [], color: 'grey-4' }
      const checkingState = { id: 'checking', title: this.$t('Checking'), transitions: [], color: 'amber' }
      const confirmedState = { id: 'confirmed', title: this.$t('Confirmed'), transitions: [], color: 'teal' }
      const closedState = { id: 'closed', title: this.$t('Closed'), transitions: [], color: 'info' }
      const cancelledState = { id: 'cancelled', title: this.$t('Cancelled'), transitions: [], color: 'danger' }
      const lockedState = { id: 'locked', title: this.$t('Locked'), transitions: [], color: 'warning' }
      const export_errorsState = { id: 'export_errors', title: this.$t('Export errors'), transitions: [], color: 'negative' }

      const states = [
        { buttons: [newState, checkingState, confirmedState, closedState, cancelledState, lockedState, export_errorsState] }
      ]

      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: states[0].buttons[0].color,
        editableFields: false,
        onClick: (state) => {
          return this.$service.deliveryServiceConsumption.save({ state }, this.consumption.id)
            .then(consumption => {
              this.setConsumption(consumption)
              this.upsertConsumption(consumption)
            })
        },
        value: states[0].buttons[0],
        options: states
      }

      const dropdown = {
        section: 'Dropdown',
        className: 'col-12 col-sm row justify-end q-pb-sm',
        options: [
          option
        ]
      }

      if (this.consumption && this.consumption.state !== option.value.id) {
        states.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === this.consumption.state
          })

          if (status) {
            option.color = group.color || status.color
            option.value = status
          }

          return status
        })
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'q-pa-sm fit--mobile text-min-content',
          options: [
            {
              id: 'title',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'col-12 text-right--mobile q-px-xs',
              valueStyle: 'white-space: nowrap',
              type: 'text',
              value: this.consumption && this.consumption.id
                ? this.$t('Shipping manifest ID') + ': ' + this.consumption.id
                : this.$t('New shipping manifest')
            }
          ]
        },
        dropdown
      ]
    },
    schema () {
      return {
        isLoading: !this.consumption,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Carrier'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'deliveryService',
                value: this.consumption && this.consumption._embedded.deliveryService,
                required: true,
                disabled: !this.consumption || !!this.consumption.id,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.deliveryService.getAll(query)
                },
                onChange: deliveryService => {
                  this.hasChange = true
                  this.updateConsumptionEmbedded({ deliveryService })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'sender',
                value: this.consumption && this.consumption._embedded.sender,
                required: true,
                disabled: !!this.consumption && !!this.consumption.id,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: sender => {
                  this.hasChange = true
                  this.updateConsumptionEmbedded({ sender })
                }
              },
              {
                type: 'date',
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'shippingDate',
                value: this.consumption && this.consumption.shippingDate,
                required: true,
                label: this.$t('Ship date'),
                get (value) {
                  if (!value) {
                    return
                  }

                  if (typeof value === 'object') {
                    return value.date
                  }

                  return value
                },
                onChange: (shippingDate) => {
                  this.hasChange = true
                  this.updateConsumption({ shippingDate })

                  if (!this.consumption.id) {
                    return
                  }

                  const query = {
                    per_page: 25,
                    filter: [
                      { field: 'state', type: 'eq', value: 'measured' },
                      {
                        type: 'innerjoin',
                        field: 'deliveryRequest',
                        parentAlias: 't',
                        alias: 'dr'
                      },
                      { field: 'deliveryService', alias: 'dr', type: 'eq', value: this.consumption._embedded.deliveryService.id },
                      { field: 'sendDate', alias: 'dr', type: 'eq', value: shippingDate },
                      { field: 'shippedByDocument', alias: 'dr', type: 'isnull' }
                    ]
                  }

                  this.loadPreprocessingTaskQuery(query)
                    .then(() => {
                      this.isRequestedTask = true
                    })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Comment'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'comment',
                value: this.consumption && this.consumption.comment,
                onChange: comment => {
                  this.hasChange = true
                  this.updateConsumption({ comment })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('ExtId'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'extId',
                value: this.consumption && this.consumption.extId,
                onChange: extId => {
                  this.hasChange = true
                  this.updateConsumption({ extId })
                }
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.params.id) {
      const pagination = {
        per_page: 25,
        page: 1,
        filter: [],
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }

      this.onRequest({ pagination })
    }

    if (!this.consumption && this.$route.params.id) {
      this.loadConsumption(this.$route.params.id)
    }

    if (!this.$route.params.id) {
      this.setNewConsumption()
      this.$service.sender.getAll({ per_page: 5, page: 1 })
        .then(({ items }) => {
          if (items.length === 1) {
            this.updateConsumption({
              _embedded: {
                ...this.consumption._embedded,
                sender: items[0]
              }
            })
          }
        })
    }
  },
  unmounted () {
    this.setConsumption(null)
  },
  methods: {
    ...mapActions([
      'loadDeliveryRequests',
      'loadConsumption',
      'saveConsumption',
      'loadPreprocessingTaskQuery'
    ]),
    ...mapMutations([
      'setDeliveryRequest',
      'updateConsumption',
      'addErrorNotification',
      'setNewConsumption',
      'setConsumption',
      'updateConsumptionEmbedded',
      'upsertConsumption'
    ]),
    onRowClick (item) {
      this.setDeliveryRequest(item)
      this.$router.push('/outbound/shipments/entity/' + item.id)
    },
    onRequest (data = {}) {
      this.isLoading = true
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      query.action = 'delivery-requests'
      query.criteria = {
        id: this.$route.params.id
      }

      return this.$service.deliveryServiceConsumption.getAll(query)
        .then(({ page, totalItems, items }) => {
          this.items = items
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    save () {
      this.isSaveLoading = true
      if(!this.$route.params.id) {
        this.updateConsumption({ state: 'new' })
      }
      this.saveConsumption()
        .then(() => {
          this.hasChange = false
          if (!this.$route.params.id) {
            this.handleBack()
          }
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    }
  }
}
</script>
